// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../components/link'
import '../components/link/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import { updateWebsiteState as updateWebsiteStateFx } from '../state/actions'

import AboutPageSchema from '../components/schema/about-page-schema'
import ContactPageSchema from '../components/schema/contact-page-schema'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'About',
  slug: '/about',
  abstract:
    "In this book Aikya shares her approach and the glimpses of wonder she encountered in facilitating the World Game with children in Auroville. World Game, also known as Sandplay, is an educational and creative invitation to express one's individuality and uniqueness, in an atmosphere of openness, joy of discovery and trust in the future. The book is authored by Aikya and the web book is created by Prisma Books.",
  breadcrumbs: [
    { title: 'Cover Page', slug: '' },
    { title: 'About', slug: '/about' },
  ],
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img1: file(relativePath: { eq: "aikya.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = ({ websiteState, updateWebsiteState, ...props }) => (
  <StandardPageWrapper pageSchema={pageSchema} {...props}>
    <AboutPageSchema />
    <ContactPageSchema />
    <div
      className="container"
      style={{ paddingTop: '1rem', paddingBottom: '1rem' }}
    >
      <h1>About</h1>
      <p>
        In this book Aikya shares her approach and the glimpses of wonder she
        encountered in facilitating the World Game with children in Auroville.
        World Game, also known as Sandplay, is an educational and creative
        invitation to express one's individuality and uniqueness, in an
        atmosphere of openness, joy of discovery and trust in the future.
      </p>
      <h2 className="as-h3">Aikya, the author</h2>
      <GatsbyImage
        className="as-paragraph"
        style={{ maxWidth: 240 }}
        image={getImage(props.data.img1)}
      />
      <p>
        Aikya is a trained psychologist, and has always been searching for her
        true purpose in life which brought her to India and Auroville. Nature’s
        intelligence or the ultimate creative force is Aikya’s inspiration for
        finding consciousness, beauty and oneness. She likes to explore new
        territories with children, adults or other living beings.
      </p>
      <h2 className="as-h3">Prisma Books, the publisher</h2>
      <p>
        Since 1988 Prisma Books of Auroville publishes good old-fashioned,
        beautifully curated books. Prisma Books offers full publishing services
        in design, editorial, printing, artwork, illustrations, photography,
        digital media, exhibition and curatorship.
      </p>
      <p>
        <Link to="https://www.prisma.haus/en/">Prisma website →</Link>
      </p>
      <h2 className="as-h3">Web book, this format</h2>
      <p>
        The web book offers content in a simple and minimal interface with only
        a few interactive elements. You can navigate between sections either by
        clicking on the CONTENT button in the header, or by clicking on
        navigational buttons in the footer. You can also move between sections
        by clicking on the LEFT and RIGHT arrows on your keyboard.
      </p>
      <p>
        You have the choice between{' '}
        <Link
          onClick={(e) => {
            e.preventDefault()
            updateWebsiteState({ ...websiteState, nav: 'standard' })
          }}
          to="#"
          aria-label="Standard Navigation"
        >
          STANDARD NAVIGATION
        </Link>
        , or{' '}
        <Link
          onClick={(e) => {
            e.preventDefault()
            updateWebsiteState({ ...websiteState, nav: 'fixed' })
          }}
          to="#"
          aria-label="Fixed Navigation"
        >
          FIXED NAVIGATION
        </Link>
        .&nbsp;Furthermore you have the choice between{' '}
        <Link
          onClick={(e) => {
            e.preventDefault()
            updateWebsiteState({ ...websiteState, mode: 'light' })
          }}
          to="#"
          aria-label="Light Mode"
        >
          LIGHT MODE
        </Link>
        ,{' '}
        <Link
          onClick={(e) => {
            e.preventDefault()
            updateWebsiteState({ ...websiteState, mode: 'dark' })
          }}
          to="#"
          aria-label="Dark Mode"
        >
          DARK MODE
        </Link>
        , and{' '}
        <Link
          onClick={(e) => {
            e.preventDefault()
            updateWebsiteState({ ...websiteState, mode: 'sepia' })
          }}
          to="#"
          aria-label="SEPIA Mode"
        >
          SEPIA MODE
        </Link>
        .
      </p>
      <p>
        <i>Glimpses of Wonder</i> is published in this format as a part of an
        emerging method of publishing being developed by Prisma Books. The idea
        is to deliver the high level of writing and design quality that you
        would find in a printed book, and to use advanced web technologies for
        publishing. This is the "web-book" format.
      </p>
      <p>
        <Link to="https://books.prisma.haus/en/web-books">
          Read more about web books →
        </Link>
      </p>
      <h2 id="on-gift-economy" className="as-h3">
        On gift economy
      </h2>
      <p>
        You can buy the paperback version of the book <i>Glimpses of Wonder</i>{' '}
        at a fixed price at auroville.com. The author and the publisher of the
        web-book format of the book have also chosen to offer the book to read
        online without a pay-wall or advertisement. This is not for free, but
        available on a Gift-Economy and Pay-What-Feels-Right basis. It is an
        invitation to give what feels right for the value you've received from
        the book and/or the support you want to give us to continue doing our
        work. Publishing in this manner is an experiment in abundance where we
        trust that when we give, we will also receive. We want you to enjoy the
        book, learn from the book, but also support the book. If it was worth
        your time, please pay for it. Here is how –
      </p>
      <p>
        <Link to="https://books.prisma.haus/en/web-books">
          You can contribute money to the publisher →
        </Link>
        <br />
        <Link to="https://books.prisma.haus/en/available-on-gift-economy">
          Learn more about publishing on Gift-Economy →
        </Link>
      </p>
      <h2 className="as-h3">Sharing this book</h2>
      <p>
        The content of the book itself is copyrighted. To share this book with
        others please refer them to this page instead.
      </p>
    </div>
  </StandardPageWrapper>
)

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    websiteState: state.websiteState,
  }),
  (dispatch) => ({
    updateWebsiteState(payload) {
      dispatch(updateWebsiteStateFx(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
